<template>
  <div class="heroflow-landing full-width">
    <div class="hero-reviews">
      <div class="hero-subsection__title">
        {{ t('why_use_tourhero') }}
      </div>
      <div class="horizontal-scroll">
        <div class="horizontal-scroll__body">
          <Cards />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useShared } from "@/helpers/vueHelpers.js";

defineOptions({name: 'ReviewIndex'});
import Cards from './Cards.vue';

const { t } = useShared();
</script>

<style lang="scss" scoped>
.heroflow-landing {
  margin-top: $spacing-12;
  @media (min-width: $breakpoint-m) {
    margin-top: $spacing-20;
    margin-bottom: $spacing-10;
  }
}
.hero-subsection__title {
  margin-top: 0;
}
</style>

<i18n lang="yaml">
en:
  why_use_tourhero: Why use TourHero
</i18n>
