<template>
  <div class="hero full-width">
    <div class="hero-partners">
      <div class="hero-title">
        {{ t('title_1') }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { useShared } from "@/helpers/vueHelpers.js";

defineOptions({name: 'MissionIndex'});

const { t } = useShared();
</script>

<style lang="scss" scoped>
$line-height-1: 36px;
$line-height-2: 24px;

.hero-partners {
  background: $brand-tertiary-light;
  padding: $spacing-6 0;
  @media (min-width: $breakpoint-m) {
    padding: $spacing-10 0;
  }
}
.hero-title {
  text-align: left;
  font-size: $text-base;
  font-weight: $font-medium;
  line-height: $line-height-2;
  @media (min-width: $breakpoint-m) {
    font-size: $text-xl;
    font-weight: $font-semibold;
    line-height: $line-height-1;
  }
}
</style>

<i18n lang="yaml">
en:
  title_1: Our mission is to bring like-minded people on unforgettable journeys.
</i18n>