<template>
  <div class="hero-upcoming-trips">
    <div class="information__title">
      {{ t('title') }}
    </div>

    <div class="horizontal-scroll">
      <div class="horizontal-scroll__body">
        <div class="hero-upcoming-trips__body">
          <Card v-for="card in cards" :key="card.id" :title="card.title" :content="card.content" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useShared } from "@/helpers/vueHelpers.js";

defineOptions({name: 'InformationIndex'});
import Card from './Card.vue';

const { t } = useShared();

const cards = [
  {
    id: 0,
    title: 'Competitive pricing',
    content: 'We partner with local operators to get you the best prices with the group volume we can bring'
  },
  {
    id: 1,
    title: 'Flexible payment plan',
    content: 'We offer flexible payment options – including monthly installments, up to 30 days before trip departure.'
  },
  {
    id: 2,
    title: 'Full customization',
    content: 'Free travel concierge service to customize your trip'
  },
  {
    id: 3,
    title: 'Lead a trip, travel for free',
    content: 'Plan your own trip and travel with 10+ people to travel for free'
  },
  {
    id: 4,
    title: 'Logistical support',
    content: 'On-the-ground support for all your travel logistical needs'
  },
  {
    id: 5,
    title: 'Compare destinations',
    content: 'Explore and compare destinations side-by-side to help you decide where to go'
  }
]
</script>

<style lang="scss" scoped>
$line-height-1: 36px;
$line-height-2: 40px;

.hero-upcoming-trips {
  margin: $spacing-6;
  @media (min-width: $breakpoint-m) {
    margin: $spacing-10 0;
  }
}

.information__title {
  margin-bottom: $spacing-6;
  font-size: $text-2xl;
  line-height: $line-height-1;
  @media (min-width: $breakpoint-m) {
    margin-bottom: $spacing-8;
    font-size: $text-4xl;
    line-height: $line-height-2;
  }
  font-weight: $font-bold;
}
.information__question {
  width: 100%;
  display: flex;
  justify-content: left;
  margin-top: 0;
  @media (min-width: $breakpoint-m) {
    justify-content: center;
    margin-top: $spacing-8;
  }
}
.horizontal-scroll {
  width: 100%;
  overflow: auto;
  margin-bottom: $spacing-6;
  @media (min-width: $breakpoint-m) {
    margin-bottom: unset;
  }
}
.horizontal-scroll__body {
  width: 100%;
  @media (min-width: $breakpoint-m) {
    width: unset;
  }
}
.hero-upcoming-trips__body {
  display: grid;
  row-gap: $spacing-6;
  padding: 0;
  @media (min-width: $breakpoint-m) {
    display: grid;
    column-gap: $spacing-6;
    row-gap: $spacing-8;
  }
}
</style>

<i18n lang="yaml">
en:
  title: What you get
</i18n>
