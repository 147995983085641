<template>
  <section class="hero-partners">
    <div class="hero-partners__title">
      {{ t('title') }}
    </div>
    <div class="hero-partners__partners">
      <div class="hero-partners__image">
        <img :src="`https://assets.tourhero.com/tourhero/pages/home/partners/forbes.png`">
      </div>
      <div class="hero-partners__image">
        <img :src="`https://assets.tourhero.com/tourhero/pages/home/partners/condenast.png`">
      </div>
      <div class="hero-partners__image">
        <img :src="`https://assets.tourhero.com/tourhero/pages/home/partners/natgeo.png`">
      </div>
      <div class="hero-partners__image">
        <img :src="`https://assets.tourhero.com/tourhero/pages/home/partners/lonelyplanet.png`">
      </div>
      <div class="hero-partners__image">
        <img :src="`https://assets.tourhero.com/tourhero/pages/home/partners/vice.png`">
      </div>
      <div class="hero-partners__image">
        <img :src="`https://assets.tourhero.com/tourhero/pages/home/partners/nyt.png`">
      </div>
    </div>
  </section>
</template>

<script setup>
import { useShared } from "@/helpers/vueHelpers.js";

defineOptions({name: 'PartnerIndex'});

const { t } = useShared();
</script>

<i18n lang="yaml">
en:
  title: Our tour partners were featured on
</i18n>