<template>
  <div class="hero-explore-country" :id="id">
    <a :href="countryLink">
      <div class="hero-explore__feature-image">
        <img :src="scene">
      </div>
      <div class="hero-explore__country">
        <div class="hero-explore__flag">
          <img :src="flag">
        </div>
        <div class="hero-explore__country-name-and-price">
          <div class="hero-explore__country-name">
            {{ countryName }}
          </div>
          <div class="hero-explore__price">
            <span>From</span>
            <Money class="hero-explore__price-money" :currency="currency" :value="price" />
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script setup>
import { computed } from "vue";
import Money from '@/components/Money.vue';
import { dasherize } from '@/utils/countryUtils.js';

import { useShared } from "@/helpers/vueHelpers.js";

const { country } = defineProps({
  country: {
    type: Object,
    required: false,
    default: () => ({})
  }
});
const { locale } = useShared();
const id = computed(() => `country-${country.name.toLowerCase()}`);
const countryLink = computed(() => `/${locale.value}/country/${dasherize(country.name)}`);
const scene = computed(() => country.featureImages[0]?.url);
const flag = computed(() => `https://flagcdn.com/16x12/${country.countryCode.toLowerCase()}.png`);
const countryName = computed(() => country.name);
const currency = computed(() => country.price.currencyIso);
const price = computed(() => country.price.cents);
</script>

<style lang="scss" scoped>
.hero-explore__price {
  display: flex;
  gap: $spacing-1;
}
</style>
