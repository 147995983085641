<template>
  <div class="heroflow-landing-gallery__photos">
    <div v-for="photo in photos" :key="photo.image" :class="`heroflow-landing-gallery__photo-wrapper ${photo.position}`">
      <img :src="`https://assets.tourhero.com/tourhero/pages/home/social/${photo.image}`">
    </div>
  </div>
</template>

<script setup>
const photos = [
  {image: 'mountain.jpg', position: 'grid-1-1 photo-horz-xs'},
  {image: 'valley.jpg', position: 'grid-2-1 photo-vert'},
  {image: 'sea.jpg', position: 'grid-2-2 photo-horz-xs'}
]
</script>

<style lang="scss" scoped>
.heroflow-landing-gallery__photos {
  display: none;
  @media (min-width: $breakpoint-m) {
    display: grid;
  }
}
</style>