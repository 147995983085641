<template>
  <div class="hero-explore">
    <div class="hero-subsection__title">
      {{ t('title') }}
    </div>

    <div class="horizontal-scroll">
      <div class="horizontal-scroll__body">
        <div class="hero-explore__subregions">
          <div
            v-for="continent in uniqueContinents"
            :key="continent"
            class="hero-explore__subregion"
          >
            <button
              type="button"
              class="button"
              :class="{
                'button--tertiary' : activeContinent === continent,
                'hero-explore__subregion-button' : activeContinent !== continent
              }"
              @click="selectContinent(continent)"
            >
              {{ continent }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="hero-explore__body">
      <div class="horizontal-scroll">
        <div class="horizontal-scroll__body">
          <div class="hero-explore-subregion">
            <Card
              v-for="country in selectedCountries"
              :key="country.name"
              :country="country"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { camelizeKeys } from 'humps';
import { uniq } from 'lodash';
import Card from './Card.vue';
import { ref, computed, onMounted } from "vue";
import { useShared } from "@/helpers/vueHelpers.js";

const { t } = useShared();
const countriesJson = JSON.parse(document.getElementById('continent-countries-json').innerHTML);
const countries = camelizeKeys(countriesJson);
const selectedCountries = ref([]);
const activeContinent = ref(null);

const uniqueContinents = computed(() => {
  const continents = countries.map(c => c.continent);
  return uniq(continents).sort((a, b) => {
    if (a === 'North America') {
      return -1;
    } else if (b === 'North America') {
      return 1;
    }
    return a.localeCompare(b);
  });
});

onMounted(() => initSelection(uniqueContinents.value));

function initSelection(continents) {
  activeContinent.value = continents[0];
  selectContinent(continents[0]);
}

function selectContinent(continent) {
  activeContinent.value = continent;
  selectedCountries.value = countries.filter(c => c.continent === continent);
}
</script>

<style lang="scss" scoped>
.hero-subsection__title {
  margin-top: 0;
  font-size: $text-2xl;
  font-weight: $font-bold;
  @media (min-width: $breakpoint-m) {
    margin-top: $spacing-10;
    font-size: 32px;
  }
}
.hero-explore__body {
  margin-bottom: $spacing-6;
  @media (min-width: $breakpoint-m) {
    margin-bottom: $spacing-10;
  }
}
</style>

<i18n lang="yaml">
en:
  title: Explore the world
</i18n>
