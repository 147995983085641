<template>
  <div class="hero-content">
    <Gallery :isSignedIn="userSignedIn" />
  </div>
  <Partner />
  <div class="hero-content">
    <Information />
    <Explore />
  </div>
  <Mission />
  <div class="hero-content">
    <Review />
    <Itinerary />
  </div>
</template>

<script setup>
import Gallery from '@/modules/Home/Gallery/index.vue';
import Partner from '@/modules/Home/Partner/index.vue';
import Information from '@/modules/Home/Information/index.vue';
import Explore from '@/modules/Home/Explore/index.vue';
import Mission from '@/modules/Home/Mission/index.vue';
import Review from '@/modules/Home/Review/index.vue';
import Itinerary from '@/modules/Home/Itinerary/index.vue';
import api from '@/utils/api.js';
import { deserializeJsonApi } from '@/utils/jsonApiDeserializer.js';
import { getCookieValue, eraseCookie } from '@/utils/cookieUtils.js';
import { computed, onMounted, ref } from "vue";

const user = ref(null);
const userSignedIn = computed(() => !!user.value);

onMounted(async () => {
  if (getCookieValue('_tourhero_loggedin_cache') !== "loggedin") return;

  try {
    const result = await api.get('/api/user');
    if (Object.keys(result.data).length) {
      user.value = await deserializeJsonApi(result.data);
    }
  } catch (err) {
    console.log(err);
    eraseCookie('_tourhero_loggedin_cache');
  }
});
</script>
