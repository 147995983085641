<template>
  <div class="hero-reviews__container" :class="{ 'hero-reviews__container_expanded': expanded }">
    <Card
      v-for="review in reviews"
      :key="review.id"
      :review="review"
      @expand="expand"
    />
  </div>
</template>

<script setup>
import Card from './Card';
import { computed, ref } from "vue";

const reviews = [
  {
    id: 'sofia-message',
    link: 'https://www.tourhero.com/en/epic-adventures/morocco/moroccos-magnificent-coast-and-desert-with-sofia-elhusseini-371697',
    message: '“Amazing experience! TourHero proposed activities that I hadn’t considered and ended up being some of the favorites of the group. Also, details throughout the trip elevated the experience, making me want to always travel this way! I could relax knowing things where taken care of and if anything came up they would quickly solve it.”',
    image: 'https://assets.tourhero.com/uo6nq4mbgaxcn6m0sa6x997inpwb',
    name: 'Sofia',
    trip: 'Morocco’s Magnificent Coast & Desert with Sofia Elhusseini'
  },
  {
    id: 'kenichi-message',
    link: 'https://www.tourhero.com/en/epic-adventures/japan/8-day-ultimate-sightseeing-adventure-in-japan-with-yale-som-japan-club-706518',
    message: '“TourHero is a great service that provides excellent support to anyone planning a trek. They offer advice based on their extensive experience, beautiful trip brochures, excellent web pages that are consistent all the way through to payment, and always provide a quick response, eliminating the hassle of dealing with a travel agent. I highly recommend using TourHero to organize your group trip.”',
    image: 'https://assets.tourhero.com/kqiuznzs680ham53jhu7t3jrtt3j',
    name: 'Kenichi',
    trip: '8-Day Ultimate Sightseeing Adventure in Japan with Yale SOM Japan Club'
  },
  {
    id: 'eva-message',
    link: 'https://www.tourhero.com/en/epic-adventures/ecuador/ecuador-explorer-andes-north-to-south-with-eva-seelye-3854',
    message: '“I just finished up a 7-day adventure-focus trip to Ecuador with 9 amazing human beings and it couldn’t have been more fun. Overall incredible experience, I would’ve never been able to plan this whole thing by myself given the time period and number of people. Being a TourHero was really fun, I got to plan a trip with all the things that I’d really enjoy. We all shared a lot of the same interest which was really fun because we were all there for the itinerary and it was just quick and easy process! Also because I didn’t have to do actual planning aside from just telling what I wanted to do, they did the rest. It was amazing. I definitely would recommend being a TourHero to my friends.”',
    image: 'https://assets.tourhero.com/iagptfm9zgrb45p7gxx90u1dt1al',
    name: 'Eva',
    trip: 'Ecuador Explorer: Andes North to South with Eva Seelye'
  },
  {
    id: 'saisha-message',
    link: 'https://www.tourhero.com/en/epic-adventures/costa-rica/unlocking-self-love-costa-rica-yoga-retreat-with-saisha-lacon-968336',
    message: '“My first yoga retreat was amazing. Meeting and creating memories with all of these incredible people was the best part. I teach yoga online as my main connection with my clients and several of my online clients have booked my yoga retreat so I get to meet them in person. This is something that TourHero has really supported me with gathering people together and making online to real life connection. Shout out to TourHero for planning and all of their support.”',
    image: 'https://assets.tourhero.com/34jlbx0apgx61ulm3ix34r5cq66y',
    name: 'Saisha',
    trip: 'Unlocking Self-Love: Costa Rica Yoga Retreat with Saisha Lacon'
  }
];
const expandedReviews = ref({});

const expanded = computed(() => {
  return Object.keys(expandedReviews.value).reduce((result, key) => {
    const value = expandedReviews.value[key];
    // Any one expanded is expanded, thus use OR operator
    return result || value;
  }, false);
});

function expand(reviewId, expand) {
  expandedReviews.value = {
    ...expandedReviews.value,
    [reviewId]: expand
  };
}
</script>

<style lang="scss" scoped>
.hero-reviews__container {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: $spacing-2;
  @media (min-width: $breakpoint-m) {
    gap: $spacing-6;
  }

}
.hero-reviews__container_expanded {
  :deep(.hero-reviews__review) {
    height: unset;
  }
}
</style>
