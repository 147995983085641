<template>
    <ExploreMobile v-if="mobile" />
    <ExploreDesktop v-if="desktop" />
</template>

<script setup>
import ExploreDesktop from './ExploreDesktop';
import ExploreMobile from './ExploreMobile';

import { useShared } from "@/helpers/vueHelpers.js";
defineOptions({name: 'ExploreIndex'});

const { mobile, desktop } = useShared();
</script>


