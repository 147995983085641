<template>
  <div class="heroflow-landing-gallery__messages">
    <div class="heroflow-landing-gallery__title">
      {{ t('title') }}
    </div>
    <div class="heroflow-landing-gallery__semititle">
      {{ t('semi_title') }}
    </div>

    <div class="heroflow-landing-gallery__buttons">
      <a class="button button--secondary" :href="planTripUrl">
        {{ t('plan_trip') }}
      </a>

      <a class="button theme-button" :href="exploreUrl">
        {{ t('join_trip') }}
      </a>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useShared } from "@/helpers/vueHelpers.js";

const { t, locale } = useShared();
const planTripUrl = computed(() => `/${locale.value}/plan-a-trip`);
const exploreUrl = computed(() => document.querySelector("meta[name=menuExploreUrl][content]").content ?? '');
</script>

<style lang="scss" scoped>
$line-height-1: 40px;
$line-height-2: 36px;

.heroflow-landing-gallery__messages {
  margin-left: $spacing-6;
  margin-right: $spacing-6;
  margin-bottom: $spacing-10;
  @media (min-width: $breakpoint-m) {
    margin: auto;
  }
}

.heroflow-landing-gallery__title {
  font-size: $text-2xl;
  line-height: $line-height-2;
  @media (min-width: $breakpoint-m) {
    font-size: $text-4xl;
    line-height: $line-height-1;
  }
  font-weight: $font-bold;
  color: $black;
}

.heroflow-landing-gallery__semititle {
  margin-top: $spacing-4;
  margin-bottom: $spacing-6;
  font-size: $text-base;
  font-weight: $font-medium;
  line-height: $leading-snug;
  @media (min-width: $breakpoint-m) {
    margin-top: $spacing-6;
    font-size: $text-xl;
    font-weight: $font-semibold;
    line-height: $leading-relaxed;
  }
  color: $black;
}

.heroflow-landing-gallery__buttons {
  display: flex;
  gap: $spacing-6;
  flex-direction: column;
  @media (min-width: $breakpoint-m) {
    flex-direction: row;
  }
}

.theme-button {
  padding: $spacing-3 $spacing-5;
  border: 1px solid $brand-tertiary;
  border-radius: $rounded-xs;
  font-size: $text-base;
  font-weight: $font-semibold;
  color: $brand-tertiary;
}

.theme-button:hover {
  color: $brand-tertiary;
}
</style>

<i18n lang="yaml">
en:
  title: TourHero is a social travel platform
  semi_title: We enable like-minded people to travel and create unforgettable experiences
  plan_trip: Plan a trip
  join_trip: Join a trip
</i18n>
