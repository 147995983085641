<template>
  <div class="hero-reviews__review-wrapper">
    <a :href="review.link">
      <div class="hero-reviews__review">
        <div class="hero-reviews__review-body">
          <div class="hero-reviews__message" :class="{ 'hero-reviews__message_ellipsis': collapse }">
            {{ review.message }}
          </div>
          <div class="hero-reviews__read-more" @click.prevent="toggle">
            {{ collapse ? t('read_more') : t('read_less') }}
          </div>
          <div class="hero-reviews__profile">
            <div class="hero-reviews__image">
              <img :src="review.image">
            </div>
            <div>
              <div class="hero-reviews__name">
                {{ review.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="hero-reviews__review-footer">
          <div class="hero-reviews__trip-name">
            {{ review.trip }}
            <div class="hero-reviews__link-wrapper">
              <div class="hero-reviews__link">
                <i class="icon icon-arrow-right-1" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useShared } from "@/helpers/vueHelpers.js";

const { t } = useShared();
const props = defineProps({ review: Object });
const emit = defineEmits(['expand']);
const collapse = ref(true);

function toggle() {
  collapse.value = !collapse.value;
  emit('expand', props.review.id, !collapse.value);
}
</script>

<style lang="scss" scoped>
.hero-reviews__message {
  line-height: $leading-snug;
}
.hero-reviews__message_ellipsis {
  -webkit-line-clamp: 3;
}
.hero-reviews__review {
  border-radius: $rounded-xs;
  height: 100%;
}
.hero-reviews_age {
  margin-top: $spacing-2;
  font-size: $text-base;
  font-weight: $font-bold;
  line-height: $leading-snug;
}
.hero-reviews__trip-name {
  @media (min-width: $breakpoint-xxl) {
    margin-right: $spacing-32;
  }
}
</style>

<i18n lang="yaml">
en:
  read_more: Read more
  read_less: Read less
</i18n>
