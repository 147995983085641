<template>
  <div class="hero-explore">
    <div class="hero-subsection__title">
      {{ t('title') }}
    </div>
    <div class="horizontal-scroll">
      <div class="horizontal-scroll__body">
        <div class="hero-explore__subregions">
          <div
            v-for="continent in uniqueContinents"
            :key="continent"
            class="hero-explore__subregion"
          >
            <button
              type="button"
              class="button"
              :class="{
                'button--tertiary' : activeContinent === continent,
                'hero-explore__subregion-button' : activeContinent !== continent
              }"
              @click="selectContinent(continent)"
            >
              {{ continent }}
            </button>
          </div>

          <div class="hero-explore__subregions hero-explore__pagination">
             <div 
                class="hero-explore__pagination-right"
                :class="{
                  'hero-explore__pagination-active' : paginatedCount * perPage < allCountries.length,
                  'hero-explore__pagination-disabled' : paginatedCount * perPage >= allCountries.length
		}"
                @click="nextCountry()"
	     >
                <i class="icon icon-arrow-right-1"></i>
             </div>
             <div 
                class="hero-explore__pagination-left"
                :class="{
                  'hero-explore__pagination-active' : paginatedCount > 1,
                  'hero-explore__pagination-disabled' : paginatedCount <= 1
		}"
                @click="prevCountry()"
             >
                <i class="icon icon-arrow-left-1"></i>
             </div>
             <div class="hero-explore__pagination-count">
                {{ paginationLabel }}
             </div>
          </div>
        </div>
      </div>
    </div>

    <div class="hero-explore__body">
      <div class="horizontal-scroll">
        <div class="horizontal-scroll__body">
          <div class="hero-explore-subregion">
            <Card
              v-for="country in selectedCountries"
              :key="country.name"
              :country="country"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { camelizeKeys } from 'humps';
import { uniq } from 'lodash';
import Card from './Card.vue';
import { ref, computed, onMounted } from "vue";
import { useShared } from "@/helpers/vueHelpers.js";

const { t } = useShared();
const countriesJson = JSON.parse(document.getElementById('continent-countries-json').innerHTML);
const countries = camelizeKeys(countriesJson);
const selectedCountries = ref([]);
const activeContinent = ref(null);
const allCountries = ref([]);
const paginatedCount = ref(0);
const perPage = 4;

const uniqueContinents = computed(() => {
  const continents = countries.map(c => c.continent);
  return uniq(continents).sort((a, b) => {
    if (a === 'North America') {
      return -1;
    } else if (b === 'North America') {
      return 1;
    }
    return a.localeCompare(b);
  });
});

const paginationLabel = computed(() => {
  return `${ paginatedCount.value }/${ allCountries.value.length % perPage > 0 ? Math.floor(allCountries.value.length / perPage) + 1 : Math.floor(allCountries.value.length) / perPage }`;
});

onMounted(() => initSelection(uniqueContinents.value));

function initSelection(continents) {
  activeContinent.value = continents[0];
  selectContinent(continents[0]);
}

function selectContinent(continent) {
  activeContinent.value = continent;
  allCountries.value = countries.filter(c => c.continent === continent);
  selectedCountries.value = countries.filter(c => c.continent === continent).splice(0, perPage);
  paginatedCount.value = allCountries.value.length > 0 ? 1 : 0;
}

function prevCountry() {
  if (paginatedCount.value > 1) {
    paginatedCount.value--;
    selectedCountries.value = countries.filter(country => {
      return country.continent === activeContinent.value;
    }).splice((paginatedCount.value - 1) * perPage, perPage);
  }
}

function nextCountry() {
  if (paginatedCount.value * perPage < allCountries.value.length) {
    selectedCountries.value = countries.filter(country => {
      return country.continent === activeContinent.value;
    }).splice(paginatedCount.value * perPage, perPage);
    paginatedCount.value++;
  }
}
</script>

<style lang="scss" scoped>
.hero-subsection__title {
  margin-top: 0;
  font-size: $text-2xl;
  font-weight: $font-bold;
  @media (min-width: $breakpoint-m) {
    margin-top: $spacing-10;
    font-size: 32px;
  }
}
.hero-explore__body {
  margin-bottom: $spacing-6;
  @media (min-width: $breakpoint-m) {
    margin-bottom: $spacing-10;
  }
}
.hero-explore__pagination {
  flex-grow: 1;
  text-align: right;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 0;
}

.hero-explore__pagination-left,
.hero-explore__pagination-right {
  background-color: $gray-lighter;
  position: relative;
  border-radius: 100%;
  width: $spacing-8;
  height: $spacing-8;
  border: 1px solid $gray-lighter;
  box-sizing: border-box;
}
.hero-explore__pagination-left .icon,
.hero-explore__pagination-right .icon {
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%,-50%);
}
.hero-explore__pagination-left .icon {
  left: 50%;
}

.hero-explore__pagination-active {
  background: $white;
  cursor: pointer;
}

.hero-explore__pagination-disabled {
  background: $gray-lighter;
  color: $gray;
  cursor: not-allowed;
}
</style>

<i18n lang="yaml">
en:
  title: Explore the world
</i18n>
